
import React, { useState } from 'react';
import { Card, CardHeader, CardBody, Row, Col, Button, CardFooter } from 'reactstrap';

import Plyr from 'plyr';
import Hls from 'hls.js';
import 'plyr/dist/plyr.css';
import useFetch from '../fetch';
import ExternalVideo from './ExternalVideo';
import convertHtmlToReact, { convertNodeToReactElement } from '@hedgedoc/html-to-react';

function transform(node, index) {
    if (node.type === 'tag' && node.name === 'a') {
        node.attribs.target = "_blank";
        return convertNodeToReactElement(node, index, transform);
    }
    
    if (node.type === 'tag' && node.name === 'img') {
        node.attribs.className = "img-fluid";
        return convertNodeToReactElement(node, index, transform);
    }
    
    if (node.type === 'tag' && node.name === 'oembed') {
        return <div className="w-100">
            <ExternalVideo
                url={node.attribs.url}
                onFinish={() => {}}
            />
        </div>;
    }
    
    
}

export default function LearningModule({ module, onFinish }) {
    const [ currentSection, setCurrentSection ] = useState(0);
    const [ _module ] = useFetch('/learning-module/' + module.id);
    
    if (!_module)
        return null;
    
    const section = _module.sections[currentSection];
    const elem = section.body && convertHtmlToReact(section.body, { transform });
    
    return <Card>
        <CardHeader>
            {section.title}
        </CardHeader>
        <CardBody>
            { elem }
        </CardBody>
        <CardFooter>
            <Row>
                <Col>
                    <Button
                        type="button"
                        color="danger"
                        block
                        outline
                        onClick={() => setCurrentSection(a => --a)}
                        disabled={currentSection === 0}
                    >
                        Previous
                    </Button>
                </Col>
                { currentSection === _module.sections.length - 1 ? <Col>
                    <Button
                        type="button"
                        color="primary"
                        block
                        onClick={() => onFinish()}
                    >
                        Next
                    </Button>
                </Col> : <Col>
                    <Button
                        type="button"
                        color="primary"
                        block
                        onClick={() => setCurrentSection(a => ++a)}
                        disabled={currentSection === _module.sections.length - 1}
                    >
                        Next
                    </Button>
                </Col> }
            </Row>
        </CardFooter>
    </Card>
}
