
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { tokenized } from './api';
import { UserContext } from './UserProvider';

export var TrackContext = React.createContext();

export default function Track({ children }) {
  const [ track, setTrack ] = useState();
  const user = useContext(UserContext);
  const intl = useIntl();

  const menuId = intl.formatMessage({ id: 'MENU_ID' });

  const update = useCallback(async function() {
    const data = await tokenized.get('/track/' + menuId);
    
    setTrack({
      ...data.data,
      completed: Object.fromEntries(Object.entries(data.data.completed)
        .map(([id, date]) => [id, new Date(date)]))
    });
  }, [user.id, menuId]);

  const updateByData = useCallback(function(data) {
    var now = new Date();

    setTrack({
      ...track,
      next_category: data.next_category,
      completed: {
        ...track.completed,
        ...(Object.fromEntries(data.completed_tasks.map(a => [a, now])))
      }
    });
  }, [track]);

  useEffect(function() {
    update();
  }, []);
  
  if (!track)
    return null;
  
  return <TrackContext.Provider value={{...track, updateByData}}>
    { children }
  </TrackContext.Provider>;
}
