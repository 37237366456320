
import React from 'react';
import { Card, CardBody } from 'reactstrap';

import Plyr from 'plyr';
import Hls from 'hls.js';
import 'plyr/dist/plyr.css';

export default class extends React.Component {
    render() {
        return <YouTubePlayer key={this.props.url} url={this.props.url} onFinish={this.props.onFinish} />;
    }
}

class YouTubePlayer extends React.PureComponent {
    state = { reported: false };

    handleEnd(e) {
        this.props.onFinish && this.props.onFinish();
    }
    
    constructor(props) {
        super(props);
        this.video = React.createRef();
    }

    componentDidMount() {
        var that = this;

        this.plyr = new Plyr(this.video.current);

        this.plyr.on("timeupdate", function(e) {
            if(!that.state.reported) {
              var cTime = that.plyr.currentTime;
              var perc = cTime * 100 / that.plyr.duration;
              if(perc > 90) {
                that.state.reported = true;
                that.handleEnd(e);
              }
            }
        });
    }

    componentWillUnmount() {
        this.plyr.destroy();
    }

    render() {
        var provider;
        var id;

        if (this.props.url.indexOf("youtube") !== -1) {
            provider = "youtube";
            id = this.props.url.match(/v=(.*)/)[1];
        } else if(this.props.url.indexOf("vimeo") !== -1) {
            provider = "vimeo";
            id = this.props.url.match(/vimeo.com\/(\d+)/)[1];
        }

        return <div>
            <div ref={this.video} data-plyr-provider={provider} data-plyr-embed-id={id} />
        </div>;
    }
}