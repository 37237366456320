import React, { Component } from 'react';
import { logout } from '../../api';
import { Redirect } from 'react-router-dom';

class Logout extends Component {
    componentDidMount() {
        logout();
    }

    render() {
        return <Redirect to="/login" />
    }
}

export default Logout;

