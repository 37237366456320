
import { useEffect, useRef, useState } from 'react';
import { free, tokenized } from './api';

function useFetchInternal(fetch, url, params = null, body = null) {
  const tokenRef = useRef();
  const [ data, setData ] = useState(null);
  const [ error, setError ] = useState(null);
  const [ loading, setLoading ] = useState(false);

  useEffect(function() {
	async function update() {
	  var ab = new AbortController();
	  tokenRef.current = ab;

	  setLoading(true);
	  setError(null);
	  try {
		var res = await fetch.get(url);
		setData(res.data);
		setLoading(false);
	  } catch(e) {
		 console.error(e);
		if (!ab.signal.aborted) {
		  setLoading(false);
		  setError(e);
		}
	  }
	}

	if (!url || !fetch) {
	  setData(null);
	  setLoading(false);
	  setError(null);
	} else {
	  update();
	}

	return function() {
	  if (tokenRef.current) {
		tokenRef.current.abort();
	  }
	};
  }, [fetch, url, params, body, setData, setError, setLoading, tokenRef]);

  //if (error)
  //  throw error;

  return [ data, loading, error ];
}

export default function useFetch(url, params = null, body = null) {
  return useFetchInternal(tokenized, url, params, body);
}

export function useFreeFetch(url, params = null, body = null) {
  return useFetchInternal(free, url, params, body);
}
