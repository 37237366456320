
import EuLogoColor from '../../assets/images/eulogocolor.png';
import EuLogoWhite from '../../assets/images/eulogowhite.png';

import {Row, Col} from 'reactstrap';

export function EuLogo() {
    return <div>
        <Row className="justify-content-center">
            <Col md="8" lg="6" xl="5">
                <img className="img-fluid mt-5 mb-3 d-block mx-auto" style={{ width: '80%' }} src={EuLogoColor} alt="" />
            </Col>
        </Row>
        <p className="text-center">The European Commission’s support for the production of this publication does not constitute an endorsement of the contents, which reflect the views only of the authors, and the Commission cannot be held responsible for any use which may be made of the information contained therein.</p>
    </div>;
}

export function EuLogoMenu() {
    return <div>
        <img className="img-fluid d-block mx-auto" style={{ width: '60%' }} src={EuLogoWhite} alt="" />
    </div>;
}