import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { FormattedMessage, useIntl } from 'react-intl';

import { toggleSidebar,
    hideRightSidebar,
    changeTopbarTheme,
    changeLayout,
    changeSidebarTheme,
    changeLayoutWidth,
    changeSidebarType,
    changePreloader } from '../../../store/actions';

// MetisMenu
import MetisMenu from "metismenujs";
import CategoryMenu from "../../CategoryMenu";
import { TrackContext } from "../../../Track";
import {EuLogoMenu} from "../../../pages/Authentication/EuLogo";


class SidebarContent extends Component {
    static contextType = TrackContext;

    constructor(props) {
        super(props);
        this.state = {
            is_toggle: false,
            layoutType: this.props.layoutType,
            topbarTheme: this.props.topbarTheme,
            leftSideBarTheme: this.props.leftSideBarTheme,
            layoutWidth: this.props.layoutWidth,
            sidebarType: this.props.leftSideBarType,
            isPreloader: this.props.isPreloader
        }
        
    }

    componentDidMount() {
        document.body.setAttribute('data-sidebar','dark');
        this.initMenu();
    }

        //update local state after changing layout
        componentDidUpdate(prevProps) {
            if (prevProps !== this.props) {
              this.setState({
                layoutType: this.props.layoutType,
                topbarTheme: this.props.topbarTheme,
                leftSideBarTheme: this.props.leftSideBarTheme,
                layoutWidth: this.props.layoutWidth,
                sidebarType: this.props.leftSideBarType,
                isPreloader: this.props.isPreloader
              });
            }

            if (this.props.leftSideBarType !== prevProps.leftSideBarType) {
                this.initMenu();
            }
        }

    initMenu() {
        new MetisMenu("#side-menu");
    }

    render() {
        return (
            <React.Fragment>
                <div id="sidebar-menu">
                    <ul className="metismenu list-unstyled" id="side-menu">
                        <li>
                            <NavLink activeClassName="mm-active" to="/dashboard" className="waves-effect">
        <i className="mdi mdi-view-dashboard"></i><span className="badge badge-pill badge-primary float-right">{Object.keys(this.context.completed || {}).length} / {this.context.total_tasks}</span>
                                <span><FormattedMessage id="DASHBOARD" /></span>
                            </NavLink>
                        </li>

                        <AutoCategoryMenu />

                        <li className="menu-title"><FormattedMessage id="PROFILE" /></li>

                        <li><Link to="/certificate" className="waves-effect"><i className="mdi mdi-certificate"></i> <span><FormattedMessage id="CERTIFICATE" /></span></Link></li>

                        <li>
                            <NavLink activeClassName="mm-active" to="/profile" className="waves-effect">
                                <i className="mdi mdi-account-circle"></i>
                                <span><FormattedMessage id="MY_PROFILE" /></span>
                            </NavLink>
                        </li>

                        <li><Link to="/logout" className="waves-effect"><i className="mdi mdi-power"></i> <span><FormattedMessage id="LOGOUT" /></span></Link></li>
                    </ul>
                </div>
                <EuLogoMenu />
            </React.Fragment>
        );
    }
}

function AutoCategoryMenu() {
    const intl = useIntl();
    const id = intl.formatMessage({ id: 'MENU_ID' });

    return <CategoryMenu key={id} id={id} />;
}

const mapStatetoProps = state => {
    const { is_toggle, leftSideBarType, layoutType, leftSideBarTheme, layoutWidth, topbarTheme, isPreloader } = state.Layout;
    return {  is_toggle, leftSideBarType, layoutType, leftSideBarTheme, layoutWidth, topbarTheme, isPreloader };
}

export default withRouter(connect(mapStatetoProps, {
    toggleSidebar,
    hideRightSidebar,
    changeLayout,
    changeTopbarTheme,
    changeSidebarTheme,
    changeLayoutWidth,
    changeSidebarType,
    changePreloader
})(SidebarContent));
