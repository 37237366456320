import React, { Component } from 'react';
import { Row, Col, CardHeader, CardBody, CardTitle, Card, CardFooter } from "reactstrap";
import { connect } from "react-redux";

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";
import Knob from '../AllCharts/knob/knob';
import { tokenized } from '../../api';
import MiniCard from "./mini-card";
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { Avatar } from '../../component/Common/UserInfo';

class Stars extends React.Component {
    render() {
        const { value } = this.props;

        return <div className="button-list mt-4 mb-4" style={{ fontSize: '20px', color: 'orange' }}>
            { value >= 1 && <i className="fa fa-star" /> }
            { value >= 2 && <i className="fa fa-star" /> }
            { value >= 3 && <i className="fa fa-star" /> }
            { value >= 4 && <i className="fa fa-star" /> }
            { value >= 5 && <i className="fa fa-star" /> }
        </div>;
    }
}

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems : [
                
            ]
        }
    }

    async componentDidMount() {
        var [ profile, stats, categories ] = await Promise.all([
            tokenized.get("/user/me"),
            tokenized.get("/stats/overview/" + this.props.intl.formatMessage({ id: 'MENU_ID' })),
            tokenized.get("/category/" + this.props.intl.formatMessage({ id: 'MENU_ID' }))
        ]);

        const categoryStats = await Promise.all(categories.data.childerens
            .map(a => tokenized.get("/stats/overview/" + a.id).then(b => b.data)));

        var stats2 = categories.data.childerens.map((a, i) => ({ category: a, stats: categoryStats[i] }));
        console.log(stats2);
        this.setState({ profile: profile.data, stats: stats.data, categories: stats2 });
    }

    state = {};

    render() {
        const { profile, stats, categories } = this.state;

        if (!profile || !stats)
            return null;

        var card = (a, b) => a !== null && <FormattedMessage id={ (b === a) ? 'DASHBOARD.NO_CHANGE' : (b > a ? "DASHBOARD.INCRASE" : "DASHBOARD.DECRASE") } values={{ count: (b - a) }} /> || null;
        
        const reports = [
            { title : "DASHBOARD.TOTAL_MEMBERS", icon: "mdi-account-box", result : card(stats.prev_total_members, stats.total_members), value : stats.total_members, color : "info" },
            { title : "DASHBOARD.YOUR_RANK", icon : "mdi-finance", result : card(stats.prev_rank, stats.rank), value : stats.rank, color : "info" },
            { title : "DASHBOARD.YOUR_SCORE", icon : "mdi-scoreboard", result : card(stats.prev_score, stats.score), value : stats.score, color : "info" },
            { title : "DASHBOARD.STATUS", icon : "mdi-account", result :  ((profile.badge && profile.badge.stars) || 1) + ' stars', value : (profile.badge && profile.badge.name) || <FormattedMessage id="BADGE.NEW_MEMBER" />, color : "info" },
        ];

        return (
            <React.Fragment>
                <Row>
                    <MiniCard reports={reports} />
                </Row>
                <Row>
                    <Col md="8">
                        <Card>
                            <CardHeader>
                                <h5 className="card-title mb-0"><FormattedMessage id="DASHBOARD.PROGRESS" /></h5>
                            </CardHeader>
                            <CardBody>
                                <Row className="text-center justify-content-center">
                                    { categories.map(a => <Col key={a} lg="3" className="text-center" dir="ltr">
                                        <h5 className="font-size-16 mb-4">{ a.category.name }</h5>
                                        <Knob
                                            value={Math.round((a.stats.completed_modules / a.stats.total_modules) * 100)}
                                            height={175}
                                            width={150}
                                            fgColor="#7a6fbe"
                                            displayCustom={()=> {return false}}
                                            readOnly
                                        />
                                    </Col>) }
                                    { stats.next_category && <Col lg="3" className="d-flex flex-column">
                                        <h5>Current Task</h5>
                                        <div className="d-flex flex-column justify-content-center" style={{ flex: '1' }}>
                                            <Link className="text-underline btn btn-outline-primary" to={"/category/" + stats.next_category.id}>{(stats.next_category.parent && stats.next_category.parent.name) || ''} / {stats.next_category.name}</Link>
                                            <br/>
                                            <span className="text-muted"><FormattedMessage id="NEXT_TASK_BUTTON" /></span>
                                        </div>
                                    </Col> }
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md="4" className="d-flex">
                        { this.state.profile && <Card style={{ flex: '1', flexGrow: '1' }}>
                            <CardHeader>                   
                                <h5 className="card-title mb-0"><FormattedMessage id="DASHBOARD.PROFILE" /></h5>
                            </CardHeader>                            
                            <CardBody className="text-center d-flex flex-column justify-content-between">
                                <Avatar index={this.state.profile.avatar_index} className="rounded-circle" style={{ width: '35%', margin: '0 auto' }} />
                                <div>
                                    <h5>{this.state.profile.name}</h5>
                                    <p>{this.state.profile.email}</p>
                                </div>
                                <p><span className="badge badge-success-inverse font-lg" style={{ fontSize: '20px', padding: '10px 20px' }}>{ (profile.badge && profile.badge.name) || 'New member' }</span></p>
                                <Stars value={ (profile.badge && profile.badge.stars) || 1 } />
                            </CardBody>
                            <CardFooter className="text-center">
                                <Row>
                                    <Col size="6" className="border-right">
                                        <h4>{ stats.completed_modules }</h4>
                                        <p className="my-2"><FormattedMessage id="DASHBOARD.COMPLETED_TASKS" /></p>
                                    </Col>
                                    <Col size="6">
                                        <h4>{ stats.total_modules }</h4>
                                        <p className="my-2"><FormattedMessage id="DASHBOARD.TOTAL_TASKS" /></p>
                                    </Col>
                                </Row>
                            </CardFooter>
                        </Card>}
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

const Dash = connect(null, { setBreadcrumbItems })(injectIntl(Dashboard));

function AutoDashboard() {
    const intl = useIntl();
    const id = intl.formatMessage({ id: 'MENU_ID' });

    return <Dash key={id} id={id} />;
}


export default AutoDashboard;