
import React, { useEffect, useState } from 'react';
import { Container, Button } from 'reactstrap';

import { logout, tokenized } from './api';
import 'react-toastify/dist/ReactToastify.css';
import { useCallback } from 'react';
import Swal from 'sweetalert2';
import { useIntl } from 'react-intl';

var UserContext = React.createContext();
var MainContext = React.createContext();

export { UserContext, MainContext };

export default function UserProvider({ children }) {
    const [ user, setUser ] = useState();

    const intl = useIntl();

    const updateUser = useCallback(async () => {
        const newUser = await tokenized.get('/user/me');
        setUser(newUser.data);
    }, []);

    const loading = useCallback(async (method) => {
        Swal.fire({
            title: 'Please wait',
            html: 'Saving your changes.',
            allowOutsideClick: false,
            onBeforeOpen: () => {
                Swal.showLoading();
            },
        });

        try {
            await method();

            await Swal.fire({
                title: 'Success',
                html: 'Changes were successfully saved',
                icon: "success"
            });
        } catch(e) {
            if (e.response && e.response.data && e.response.data.localized) {
                await Swal.fire({
                    html: intl.formatMessage({ id: e.response.data.message }),
                    icon: 'error'
                });
            } else {
                await Swal.fire({
                    title: 'Error occured!',
                    html: e.message || '',
                    icon: 'error'
                });
            }
            
            throw e;
        } finally {
            Swal.close();
        }
    }, [intl]);

    useEffect(function() {
        updateUser();
    }, [updateUser]);

    if (!user)
        return null;

    return <UserContext.Provider value={{ ...user, update: updateUser }}>
        <MainContext.Provider value={{ loading: loading }}>
          { children }
        </MainContext.Provider>
    </UserContext.Provider>;
}
