
import React, { useEffect } from 'react';
import "nprogress/nprogress.css";
import NProgress from 'nprogress';
import { set_hook } from "./api";

export default function Progress({ children }) {
  useEffect(function() {
    return set_hook(() => NProgress.start(), () => NProgress.done());
  });

  return children;
}
