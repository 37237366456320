
import React from 'react';
import { Card, CardHeader, CardBody } from 'reactstrap';

export default class extends React.Component {
    render() {
        const { category } = this.props;

        return <Card className="flex-full">
            <CardHeader>
                <b>External Reading</b>
            </CardHeader>
            <CardBody>
                <p>Please follow the following link to proceed the reading section.</p>
                <p><a className="btn btn-primary" target="_blank" href={category.reading_link}>{ category.reading_title }</a></p>
            </CardBody>
        </Card>;
    }
}
