
import React, { Component, useReducer, useRef, useState } from 'react';

import { Container, Row, Col, Button, FormGroup, Label, Input, CustomInput, CardFooter, CardTitle, CardHeader, Card, CardBody } from 'reactstrap';

import Validator from './Validator';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import SimpleReactValidator from 'simple-react-validator';
import { tokenized } from '../../api';
import Swal from 'sweetalert2';
import { MainContext, UserContext } from '../../UserProvider';
import { useCallback } from 'react';
import { useContext } from 'react';
import { useEffect } from 'react';

const roles = [
    "ROLE.UNSPECIFIED",
    "ROLE.TRAINER",
    "ROLE.ATHLETE",
    "ROLE.STUDENT",
    "ROLE.SPORT_EDUCATOR",
    "ROLE.SPORTS_LEADER",
    "ROLE.INVESTOR",
    "ROLE.TECHNICAL"
];

const RoleSelector = injectIntl(class extends React.Component {
    render() {
        const { value, onChange, intl } = this.props;

        return <select className="custom-select" value={value} onChange={a => onChange(a.currentTarget.selectedIndex)}>
            { roles.map((a, i) => <option key={i} value={i}>{intl.formatMessage({ id: a })}</option>)}
        </select>;
    }
});

const avatars = [
    require('../../assets/avatars/01.jpg'),
    require('../../assets/avatars/02.jpg'),
    require('../../assets/avatars/03.jpg'),
    require('../../assets/avatars/13.jpg'),
    require('../../assets/avatars/04.jpg'),
    require('../../assets/avatars/05.jpg'),
    require('../../assets/avatars/06.jpg'),
    require('../../assets/avatars/07.jpg'),
    require('../../assets/avatars/08.jpg'),
    require('../../assets/avatars/09.jpg'),
    require('../../assets/avatars/14.jpg'),
    require('../../assets/avatars/10.jpg'),
    require('../../assets/avatars/11.jpg'),
    require('../../assets/avatars/12.jpg'),
    require('../../assets/avatars/15.jpg')
];

export class Avatar extends React.Component {
    render() {
        const { index, src, className, style } = this.props;

        return <img
            className={className || "avatar"}
            src={src || avatars[(index || 0) % avatars.length]}
            style={style}
        />;
    }
}

class AvatarChooser extends React.Component {
    onClick(index, e) {
        e.preventDefault();
        this.props.onChange(index);
    }

    render() {
        const { index } = this.props;

        return <div className="avatar-chooser">
            <Avatar index={index} />

            <div>
                { avatars.map((a, i) => <a key={i} className={"avatar-select" + (i === index ? ' selected' : '')} onClick={this.onClick.bind(this, i)}>
                    <Avatar src={a} />
                </a>)}
            </div>
        </div>
    }
}

export default function UserInfo() {
    const [ user, setUser ] = useState();
    const intl = useIntl();
    const userContext = useContext(UserContext);
    const mainContext = useContext(MainContext);
    const validator = useRef(new SimpleReactValidator({
        messages: {
            email: intl.formatMessage({ id: 'VALIDATION.EMAIL' }),
            default: intl.formatMessage({ id: 'VALIDATION.REQUIRED' })
        }
    }));

    const [ _, forceUpdate ] = useReducer(x => x + 1, 0);

    const updateUser = useCallback((e) => {
        e.preventDefault();

        if (!validator.current.allValid()) {
            validator.current.showMessages();
            forceUpdate();
            return ;
        }
        
        mainContext
            .loading(async () => {
                var res = await tokenized.patch('/user/' + userContext.id, user);
                setUser(res.data);
                await userContext.update();
            });
    }, [user, userContext, mainContext]);

    useEffect(() => {
        async function updateUser() {
            setUser((await tokenized.get('/user/me')).data);
        }

        updateUser();
    }, []);

    if (!user)
        return null;

    return <form onSubmit={updateUser}>
        <Card>
            <CardHeader>
                <CardTitle><b>Profile</b></CardTitle>
            </CardHeader>
            <CardBody>
                <Row>
                    <Col md="3">
                        <AvatarChooser
                            index={user.avatar_index || 0}
                            onChange={index => setUser({ ...user, avatar_index: index })}
                        />
                    </Col>
                    <Col md="9" className="d-flex flex-column justify-content-around">
                        <FormGroup>
                            <Label><FormattedMessage id="AUTH.NAME" /></Label>
                            <Validator
                                name="first_name"
                                value={user.name}
                                type="required"
                                validator={validator}>
                                <Input type="text"
                                    value={user.name}
                                    onChange={a => setUser({ ...user, name: a.currentTarget.value  } )}
                                />
                            </Validator>
                        </FormGroup>
                        <FormGroup>
                            <Label><FormattedMessage id="ROLE" /></Label>
                            <RoleSelector
                                value={user.role_id || 0}
                                onChange={a => setUser({ ...user, role_id: a })}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label><FormattedMessage id="AUTH.EMAIL" /></Label>
                            <Validator
                                name="email"
                                value={user.email}
                                type="required|email"
                                validator={validator}>
                                <Input type="text"
                                    value={user.email}
                                    onChange={a => setUser({ ...user, email: a.currentTarget.value  } )}
                                />
                            </Validator>
                        </FormGroup>
                    </Col>
                </Row>
        </CardBody>
        <CardFooter className="text-right">
            <Button type="submit" color="primary"><i className="fa fa-save mr-1" /> <FormattedMessage id="PROFILE.SAVE" /></Button>
        </CardFooter>
    </Card>
    </form>;
}
