import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { Provider as Provider2 } from './component/Common/Orderable';

import store from './store';
import LanguageProvider from './lang';
import Progress from './Progress';

const app = (
    <Progress>
        <Provider store={store}>
            <Provider2>
                <LanguageProvider>
                    <BrowserRouter>
                        <App />
                    </BrowserRouter>
                </LanguageProvider>
            </Provider2>
        </Provider>
    </Progress>
);

ReactDOM.render(app, document.getElementById('root'));
serviceWorker.unregister();
