import React, { Component } from 'react';
import { LanguageSwitcherBasic } from '../../../lang';


class LanguageMenu extends Component {
    render() {
        return (
            <LanguageSwitcherBasic className={this.props.class} />
        );
    }
}

export default LanguageMenu;