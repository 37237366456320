
import React from 'react';
import { tokenized } from '../api';
import { Card, CardBody, Input, Row, CustomInput, CardHeader, Col, Button } from 'reactstrap';
import { className, classJoin as cn } from 'css-classname';

import Dom2react from 'dom-to-react';
import { FormattedMessage } from 'react-intl';
import Orderable, { Matchable, Orderable2, Provider } from '../component/Common/Orderable';

export default class extends React.Component {
    state = { };

    async componentDidMount() {
        var session = await tokenized.post('/exam/' + this.props.id + '/session');

        this.setState({ session: session.data, index: session.data.evaluated_at ? session.data.exam.questions.length : 0, answers: session.data.answers || {} });
    }

    async handleSubmit() {
        this.setState({ loading: true });
        try {
            var session = await tokenized.post('/session/' + this.state.session.id, { answers: this.state.answers });
            if (this.props.onFinish && (session.data.exam.is_task || session.data.status === 2)) {
                this.props.onFinish();
            }

            this.setState({ session: session.data, index: session.data.exam.questions.length, answers: session.data.answers, loading: false });
        } finally {
            //this.setState({ loading: false });
        }
    }

    async handleRetry() {
        this.setState({ loading: true });
        try {
            var session = await tokenized.post('/exam/' + this.props.id + '/session', null, { params: { retry: true } });

            this.setState({ session: session.data, index: 0, answers: session.data.answers || {} });
        } finally {
            this.setState({ loading: false });
        }
    }

    render() {
        if(!this.state.session)
            return null;

        if (this.state.loading) {
            return null;
        }

        const { session, index, answers } = this.state;

        const questions = session.exam.questions;
        const q = questions[index];
        const a = q && answers[q.id];

        return <>
            <Card className="flex-full">
                <CardHeader>
                    <b>{ session.exam.message }</b>
                </CardHeader>
                <CardBody className="d-flex align-items-center">
                    {q && <Question
                        session={session}
                        toolkit={!!session.exam.is_task}
                        question={q}
                        answer={a}
                        onNext={questions.length !== index && (a => this.setState({ index: index + 1 }))}
                        onChange={(a, b) => this.setState( b ?  { answers: { ...this.state.answers, [q.id]: a, ...(a || []) } } : { answers: { ...this.state.answers, [q.id]: a } })}
                    />}
                    {!q && <Submission
                        toolkit={!!session.exam.is_task}
                        session={this.state.session}
                        onChange={index => this.setState({ index })}
                        onSubmit={this.handleSubmit.bind(this)}
                        onRetry={this.handleRetry.bind(this)}
                    />}
                </CardBody>
            </Card>
            <Card className="mt-3">
                <CardBody>
                    <Paginator
                        index={index}
                        toolkit={!!session.exam.is_task}
                        results={ !session.exam.is_task && session.evaluated_at && questions.map(a => session.scores[a.id] && session.scores[a.id].item1) }
                        showAnswers={!!session.evaluated_at && session.status === 2}
                        onChange={index => this.setState({ index })}
                        total={questions.length}
                    />
                </CardBody>
            </Card>
        </>;
    }
}

class Paginator extends React.Component {
    render() {
        const { results, index, showAnswers, onChange, total } = this.props;

        return <div className="footer-2 d-flex justify-content-between">
            <a onClick={a => index && onChange(index - 1)} href className={"q-box q-box-btn q-box-sq " + ((index == 0  && 'disabled') || '' )}>«</a>
            { [ ...Array(total)]
                .map((a, i) => <a key={i}
                onClick={a => onChange(i)}
                className={cn('q-box', {
                    'active': i == index,
                }, results && showAnswers && {
                    done: results[i] === 4,
                    partial: results[i] === 2,
                    wrong: results[i] === 1
                })}>
                    { i + 1 }
                    </a>) }
            <a onClick={a => index != total && onChange(total)} href className={"q-box q-box-btn q-box-sq q-box-submit looks-disabled " + (index == total  && 'active')}>{ (results && 'Results') || 'Submit' }</a>
            <a onClick={a => index != total && onChange(index + 1)} href className={"q-box q-box-btn q-box-sq " + ((index == total  && 'disabled') || '' )}>&raquo;</a>
        </div>;
    }
}

class Question extends React.Component {

    renderSingleChooice() {
        const { session, question, answer, onChange } = this.props;

        return <div>
            <div dangerouslySetInnerHTML={{ __html: question.body }} />
            <div className="mt-3">
                {question.chooices.map((a, i) => <CustomInput
                    key={i}
                    type="radio"
                    id={"chooice_" + a.id+ "_" + question.id}
                    label={a.answer}
                    checked={answer == i}
                    disabled={!!session.evaluated_at}
                    className={ (session.evaluated_at && session.status === 2 && (question.answer === i ? 'is-valid' : answer == i ? 'is-invalid' : '')) || ''}
                    onChange={a => onChange(i)}
                />)}
            </div>
        </div>;
    }

    renderMultipleChooice() {
        const { session, question, answer, onChange } = this.props;

        const realAnswers = answer || [];

        console.log(answer, realAnswers);

        return <div>
            <div dangerouslySetInnerHTML={{ __html: question.body }} />
            <div className="mt-3">
                {question.chooices.map((a, i) => <CustomInput
                    key={i}
                    type="checkbox"
                    id={"chooice_" + a.id+ "_" + question.id}
                    label={a.answer}
                    checked={!!realAnswers[i]}
                    disabled={!!session.evaluated_at}
                    className={ (session.evaluated_at && session.status === 2 && (a.is_true ? 'is-valid' : answer == i ? 'is-invalid' : '')) || ''}
                    onChange={a => onChange(  question.chooices.map((_, j) => j === i ? !realAnswers[j] : (realAnswers[j] || false) ) )}
                />)}
            </div>
        </div>;
    }

    renderTrueFalse() {
        const { question, session, answer, onChange } = this.props;

        return <div>
            <div dangerouslySetInnerHTML={{ __html: question.body }} />
            <div className="mt-3">
                <CustomInput
                    type="radio"
                    id={"chooice_" + question.id + "_yes"}
                    label="Yes"
                    checked={answer === true}
                    disabled={!!session.evaluated_at}
                    onChange={a => onChange(true)}
                />
                <CustomInput
                    type="radio"
                    id={"chooice_" + question.id + "_no"}
                    label="No"
                    checked={answer === false}
                    disabled={!!session.evaluated_at}
                    onChange={a => onChange(false)}
                />
            </div>
        </div>
    }

    renderFillBlanks() {
        const { question, session, answer, onChange } = this.props;

        var _answer = answer || [...Array(question.filings.length)];
        
        const d2r = new Dom2react([{
            condition(node, key) {
                return node.nodeName.toLowerCase() === 'fill';
            },

            action(node, key, level) {
                var index = +node.getAttribute('index');
                var filling = question.filings[index];

                if(filling.space > 5) {
                    return <Input
                        type="textarea"
                        style={{
                            display:'inline-block'
                        }}
                        disabled={!!session.evaluated_at}
                        value={ _answer[index] || "" }
                        onChange={e => onChange( _answer.map((a,i) => i == index ? e.currentTarget.value : a) ) }
                    />;
                }

                return <Input
                    type="text"
                    style={{
                        display:'inline-block',
                        width: (filling.space * 20) + 'px' 
                    }}
                    disabled={!!session.evaluated_at}
                    value={ _answer[index] || "" }
                    onChange={e => onChange( _answer.map((a,i) => i == index ? e.currentTarget.value : a) ) }
                />;
            }
        }]);

        var str = question.filings
            .map((a, i) => a.text_before + ' <fill index="'+i+'"></fill>' + (a.text_after || ''))
            .join('');

        var obj = new DOMParser()
            .parseFromString('<div>' + str + '</div>', 'text/html')
            .body
            .firstChild;

        return <div>
            <div>
                {d2r.prepareNode(obj, 0, 0)}
            </div>
        </div>
    }

    renderGroup() {
        const { session, question, answer, onChange } = this.props;

        return <div>
            <div dangerouslySetInnerHTML={{ __html: question.body }} />
            {question.questions
                .map(a => <div className="my-5" key={a.id}><Question
                    session={session}
                    question={a}
                    answer={answer && answer[a.id]}
                    onChange={e => onChange({ ...(answer || {}), [a.id]: e }, true)}
                /></div>)}
        </div>;
    }

    renderFreeText() {
        const { question, answer, onChange } = this.props;

        return <div>
            <div dangerouslySetInnerHTML={{ __html: question.body }} />
            <div className="mt-3">
                <Input
                    type="textarea"
                    rows={4}
                    style={{ width: '100%' }}
                    value={answer || ''}
                    onChange={a => onChange(a.currentTarget.value)}
                />
            </div>
        </div>
    }

    renderLikert() {
        const { session, question, answer, onChange } = this.props;

        return <div>
            <div dangerouslySetInnerHTML={{ __html: question.body }} />
            <div className="mt-3">
                {[
                    'LIKERT.STRONGLY_DISAGREE',
                    'LIKERT.DISAGREE',
                    'LIKERT.NEURAL',
                    'LIKERT.AGREE',
                    'LIKERT.STRONGLY_AGREE'
                ].map((a, i) => <CustomInput
                    key={i}
                    type="radio"
                    id={"chooice_" + i + '_' + question.id}
                    label={<FormattedMessage id={a} />}
                    checked={answer == i}
                    disabled={!!session.evaluated_at}
                    className={ (session.evaluated_at && session.status === 2 && (question.answer === i ? 'is-valid' : answer == i ? 'is-invalid' : '')) || ''}
                    onChange={a => onChange(i)}
                />)}
            </div>
        </div>;
    }

    renderMatching() {
        const { session, question, answer, onChange } = this.props;

        return <div>
            <div dangerouslySetInnerHTML={{ __html: question.body }} />
            <div className="mt-3">
                <Row>
                    <Matchable value={answer} setValue={a => !session.evaluated_at && onChange(a)}>
                        { question.matches
                            .map((a, i) => [
                                <div key={a.id} className="match-block">
                                    <div><b>{ i +1 }.</b> { a.question }</div>
                                </div>,
                                <div className="match-block">
                                    <div>
                                        <b>{ a.order + 1 }.</b> { a.answer }
                                    </div>
                                </div>
                            ]) }
                    </Matchable>
                </Row>
            </div>
        </div>
    }

    renderOrdering() {
        const { session, question, answer, onChange } = this.props;

        const realAnswer = answer || [...Array(question.chooices.length)].map((a, i) => i);
        const orderedList = [...question.chooices]
            .sort((a, b) => realAnswer.indexOf(a.order) - realAnswer.indexOf(b.order));

        return <div>
            <div dangerouslySetInnerHTML={{ __html: question.body }} />
            <div className="mt-3">
                <Orderable id={`matching_${question.id}`} value={orderedList} onChange={a => !session.evaluated_at && onChange(a.map(a => question.chooices.indexOf(a)))}>
                    { (a, i, props) => <div key={i} className="match-block">
                        <div>
                            <b>{ a.order + 1 }.</b> { a.answer }
                        </div>
                    </div> }
                </Orderable>
            </div>
        </div>
    }

    render() {
        const { toolkit } = this.props;
        const { type } = this.props.question;

        return <div>
            { type == 1 && this.renderSingleChooice() }
            { type == 2 && this.renderTrueFalse() }
            { type == 3 && this.renderFreeText() }
            { type == 4 && this.renderFillBlanks() }
            { type == 5 && this.renderMatching() }
            { type == 6 && this.renderGroup() }
            { type == 7 && this.renderLikert() }
            { type == 8 && this.renderMultipleChooice() }
            { type == 9 && this.renderOrdering() }
            { this.props.onNext && <Button className="mt-3" color="primary" outline onClick={this.props.onNext}><i className="fa fa-angle-double-right" />{' '}<FormattedMessage id={toolkit ? "TOOLKIT.NEXT" : "EXAM.NEXT_QUESTION"} /></Button> }
        </div>;
    }
}

class Submission extends React.Component {
    handleSubmit(e) {
        e.preventDefault();
        this.props.onSubmit();
    }

    handleRetry(e) {
        e.preventDefault();
        this.props.onRetry();
    }

    handleBack(e) {
        e.preventDefault();
        this.props.onChange(0);
    }

    render() {
        const { session } = this.props;

        if(this.props.toolkit) {
            if(session.evaluated_at) {
                return <div>
                    <h4><FormattedMessage id="TOOLKIT" /></h4>
                    <p>
                        <FormattedMessage
                            id="TOOLKIT.SAVED_AT"
                            values={{
                                date: <b>{new Date(session.evaluated_at).toLocaleString()}</b>
                            }}
                        />
                    </p>
                    <p><a href="#" onClick={this.handleBack.bind(this)} className="btn btn-outline-primary"><FormattedMessage id="TOOLKIT.VIEW_CONTENT" /></a></p>
                    <p><a href="#" onClick={this.handleRetry.bind(this)} className="btn btn-danger mt-2"><FormattedMessage id="TOOLKIT.CHANGE_CONTENT" /></a></p>
                </div>;
            }
    
            return <div>
                <h4><FormattedMessage id="TOOLKIT.END" /></h4>
                <p><FormattedMessage id="TOOLKIT.END_SAVE" /></p>
                <p><a href="#" className="btn btn-primary" onClick={this.handleSubmit.bind(this)}><FormattedMessage id="GENERAL.SAVE" /></a></p>
                <p><a href="#" onClick={this.handleBack.bind(this)} className="btn btn-outline-info">« <FormattedMessage id="GENERAL.BACK" /></a></p>
            </div>;
        }

        if(session.evaluated_at) {
            return <div>
                <h4><FormattedMessage id="EXAM" /></h4>
                <p>
                    <FormattedMessage
                        id="EXAM.SUBMITTED_AT"
                        values={{
                            date: <b>{new Date(session.evaluated_at).toLocaleString()}</b>
                        }}
                    />
                </p>
                <h4 className="mt-5"><FormattedMessage id="EXAM.RESULTS" /></h4>
                { session.status == 2 && <h5 className="text-success"><FormattedMessage id="EXAM.PASSED" /></h5> }
                { session.status == 1 && <h5 className="text-danger"><FormattedMessage id="EXAM.FAILED" /></h5> }
                <p>
                    <b>
                        <FormattedMessage
                            id="EXAM.OVERVIEW"
                            values={{
                                br: <br />,
                                score: session.statistics.score,
                                max_score: session.exam.score,
                                percentage: (session.statistics.score / session.exam.score * 100).toFixed(0)
                            }}
                        />
                    </b>
                </p>
                <p><FormattedMessage id="EXAM.CAN_GO_BACK" /></p>
                <p><a href="#" onClick={this.handleBack.bind(this)} className="btn btn-outline-primary"><FormattedMessage id="EXAM.VIEW_ANSWERS" /></a></p>
                { session.status == 1 && <p><a href="#" onClick={this.handleRetry.bind(this)} className="btn btn-danger mt-2"><FormattedMessage id="EXAM.RETAKE_EXAM" /></a></p> }
            </div>;
        }

        return <div>
            <h4><FormattedMessage id="EXAM.END" /></h4>
            <p><FormattedMessage id="EXAM.REVIEW_OR_SUBMIT" /></p>
            <p><a href="#" className="btn btn-primary" onClick={this.handleSubmit.bind(this)}><FormattedMessage id="EXAM.SUBMIT_ANSWERS" /></a></p>
            <p><a href="#" onClick={this.handleBack.bind(this)} className="btn btn-outline-info">« <FormattedMessage id="GENERAL.BACK" /></a></p>
        </div>;
    }
}